/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/mixins";
@use "scss/colours";
@import "scss/core";

// Remove the default body padding.
body {
    padding: 0;
    margin: 0;
}
#root {
    display: flex;
}
// Add some padding on the root.
.with-navbar #root {
    padding-top: 2.5rem;
    @include mixins.media-breakpoint-up(sm) { padding-top: 3rem; }

    @include colours.gthemed {
        background-color: colours.t("page-background");
    }
}

// Define the default font style.
* { font-family: "Tw cen MT", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif; }
