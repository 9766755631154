/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";


// Define the theme map.
$themes: (
    "light": (
        "text": #3C3C3C,
        "text-light": #fff,
        "background": #fff,
        "background-dark": #c3c3c3,
        "page-background": #f3f3f3,
        "primary": #2EA8E6,
        "secondary": #0F5A93,
        "highlight": #b1e3e8,
        "lowlight": #0f5168,
        "danger": #E63617,
        "danger-pressed": color.adjust(#ff8e7c, $lightness: -25%, $saturation: -50%),
        "warning": #FAB857,
        "warning-pressed": color.adjust(#fab857, $lightness: -25%, $saturation: -50%),
        "success": #2EE62E,
        "success-pressed": color.adjust(#c7d882, $lightness: -25%, $saturation: -50%),
        "low": #ff8e7c,
        "med": #fab857,
        "high": #c7d882,
        "highest": #37bcaf,
        "shadow": #5c5c5c88,
        "disabled": #666666,
        "debug": #531b93
    ),
    "dark": (
        "text": #5c5c5c,
        "text-light": #fff,
        "background": #fff,
        "background-dark": #c3c3c3,
        "page-background": #f3f3f3,
        "primary": #359dd1,
        "secondary": #006fab,
        "highlight": #b1e3e8,
        "lowlight": #0f5168,
        "danger": #ff8e7c,
        "warning": #fab857,
        "success": #c7d882,
        "low": #ff8e7c,
        "med": #fab857,
        "high": #c7d882,
        "highest": #37bcaf,
        "shadow": #5c5c5c88,
    )
);


/** Mixin used to generate the themed colour values. */
@mixin themed() {
    // Loop through all the themed values.
    @each $theme, $map in $themes {
        // Adds a new theme matcher to the current value.
        :global(.theme--#{$theme}) & {
            // Map all the themed values to a new global variable.
            $theme-map: () !global;
            @each $key, $sub in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            // Execute the mixin contents.
            @content;

            // Delete the global map.
            $theme-map: null !global;
        }
    }
}

/** Mixin used to generate the themed colour values. */
@mixin gthemed() {
    // Loop through all the themed values.
    @each $theme, $map in $themes {
        // Adds a new theme matcher to the current value.
        .theme--#{$theme} & {
            // Map all the themed values to a new global variable.
            $theme-map: () !global;
            @each $key, $sub in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            // Execute the mixin contents.
            @content;

            // Delete the global map.
            $theme-map: null !global;
        }
    }
}

/** Helper function used to query a themed value. */
@function t($key: "primary") {
    //noinspection SassScssUnresolvedVariable
    @if (variable-exists("theme-map") and $theme-map != null) {
        @return map-get($theme-map, $key);
    } @else {
        @error "No theme map was defined in the current scope. Did you wrap with @include colours.themed ?"
    }
}

//$dark-text-colour       : #5c5c5c;
//$primary-colour         : #006fab;
//$pastel-primary-colour  : lighten(desaturate($primary-colour, 0%), 55%);
//$secondary-colour       : #379ed2;
//$pastel-secondary-colour: lighten(desaturate($secondary-colour, 33%), 37.5%);
//$tertiary-colour        : #d3c9ba;
//$blue: $primary-colour;
//
//$low-colour         : #ff8e7c;
//$med-colour         : #fab857;
//$high-colour        : #c7d882;
//$highest-colour     : #37bcaf;
//
//$dark-text-colour-filter : invert(64%);
//$darker-text-colour-filter : invert(80%);
//$primary-colour-filter   : invert(73%) sepia(49%) saturate(2815%) hue-rotate(179deg) brightness(98%) contrast(101%);
//$secondary-colour-filter : invert(42%) sepia(26%) saturate(1157%) hue-rotate(156deg) brightness(90%) contrast( 89%);
//$tertiary-colour-filter  : invert( 7%) sepia(16%) saturate( 254%) hue-rotate(345deg) brightness(87%) contrast( 88%);
//
//$low-colour-filter       : invert(25%) sepia(50%) saturate(4395%) hue-rotate(317deg) brightness(118%) contrast(101%);
//$med-colour-filter       : invert(10%) sepia(19%) saturate(1782%) hue-rotate(322deg) brightness(101%) contrast( 96%);
//$high-colour-filter      : invert(10%) sepia(19%) saturate( 799%) hue-rotate( 19deg) brightness( 94%) contrast( 87%);
//$highest-colour-filter   : invert(37%) sepia(60%) saturate( 483%) hue-rotate(125deg) brightness( 92%) contrast( 80%);
//
//$blue-light: #b1e3e8;
//$blue-light-filter: invert(13%) sepia(25%) saturate(324%) hue-rotate(139deg) brightness(101%) contrast(82%);
//$blue: #359dd1;
//$blue-filter: invert(26%) sepia(61%) saturate(4283%) hue-rotate(169deg) brightness(89%) contrast(83%);
//$blue-dark: #006fab;
//$blue-dark-filter: invert(68%) sepia(63%) saturate(1676%) hue-rotate(174deg) brightness(88%) contrast(103%);
//$blue-darkest: #0f5168;
//$blue-darkest-filter: invert(76%) sepia(57%) saturate(715%) hue-rotate(150deg) brightness(92%) contrast(91%);
//
//$background-colour      : lighten(desaturate($blue-light, 50%), 15%);
