/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "./colours";
@import "~bootstrap/scss/bootstrap";
@import "./core/heading";
@import "./core/user-events";
@import "./core/cursor";

// Ensure that the page fills the screen.
html, body, #root { height: 100%; width: 100%; padding: 0; margin: 0; }
#root { overflow: hidden auto; }

// Set the style of the body.
body {
    background-color: map-get(map-get(colours.$themes, "light"), "page-background");

    &.theme--dark { background-color: map-get(map-get(colours.$themes, "dark"), "page-background"); }
}

// Clear all button styles.
button { border: unset; background: unset; outline: unset; }
