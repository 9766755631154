/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "../colours";


@mixin heading($fontSize, $bold: false, $colour: "secondary") {
    // Set the size of the heading.
    font-size: $fontSize;
    line-height: 1.2;
    //min-height: 1.2 * $fontSize;
    padding: 0;
    margin: 0;

    // Set the style of the font.
    font-weight: if($bold == true, bold, normal);
    font-style: normal;
    @include colours.themed {
        color: colours.t($colour);
    }

    // Wrap any overflowing text.
    white-space: break-spaces;
}

/** Set the style of the first level headings. */
h1 {
    @include heading(2rem, false, "primary");
}

h2 {
    @include heading(1.5rem, false);
}

h3 {
    @include heading(1.25rem, false);
}

h4 {
    @include heading(1.25rem, false, "primary");
}

h5 {
    @include heading(1rem, true);
}

h6 {
    @include heading(1rem, true, "primary");
}
